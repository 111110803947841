import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Link } from 'gatsby';

export default function Banner({ title }) {
  return (
    <Grid className="banner">
      <Link to="/" className="breadcrumb">Home</Link>
      <span className="separator"> &gt; </span>
      <span className="breadcrumb">{title}</span>
    </Grid>
  );
}

Banner.propTypes = {
  title: PropTypes.string.isRequired,
};
